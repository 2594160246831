import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ShareIcon from "@mui/icons-material/Share";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, Button, Rating } from "@mui/material";
import DishDialog from "./DishDialog";

export default function DishCard({ dish, pack }) {
  const primaryImage = dish.images ? JSON.parse(dish.images).primaryImage : "";
  return (
    <Card
      sx={{
        maxWidth: 345,
        marginLeft: "auto",
        marginRight: "auto",
        marginBlock: "10px",
      }}
    >
      <CardHeader
        action={
          <div>
            <IconButton aria-label="share">
              <ShareIcon />
            </IconButton>
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          </div>
        }
        title={dish.name}
        titleTypographyProps={{ fontSize: "16px", fontWeight: "bold" }}
      />

      {pack.images > 0 ? (
        <CardMedia
          component="img"
          height="194"
          image={primaryImage}
          alt={dish.slug}
        />
      ) : (
        ""
      )}

      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {dish.description}
        </Typography>
      </CardContent>
      <Box padding="5px" display="flex" justifyContent="space-between">
        <Rating
          name="read-only"
          value={parseInt(dish.rating)}
          readOnly
          precision={0.1}
        />
        <DishDialog dish={dish} />
      </Box>
      <CardActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="body1"
          color="red"
          fontSize="20px"
          fontWeight="bold"
        >
          Prix : {dish.price} DA
        </Typography>
        {dish.available ? (
          <Button
            variant="contained"
            size="medium"
            sx={{ backgroundColor: "#ebd4a1", color: "black" }}
          >
            Commander
          </Button>
        ) : (
          <Typography
            variant="h5"
            component="div"
            color="text.secondary"
            sx={{
              color: "red",
            }}
          >
            Non disponible
          </Typography>
        )}
      </CardActions>
    </Card>
  );
}

import * as React from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";

const data = [
  {
    src: "https://assets.afcdn.com/recipe/20190704/94705_w600.jpg",
    title: "Night view",
    version: "version 1",
  },
  {
    src: "https://assets.afcdn.com/recipe/20190704/94709_w600.jpg",
    title: "Lake view",
    version: "version 2",
  },
  {
    src: "https://assets.afcdn.com/recipe/20190704/94706_w600.jpg",
    title: "Mountain view",
    version: "version 3",
  },
];

export default function DishImagesCarousel() {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        py: 1,
        overflow: "auto",
        width: 343,
        scrollSnapType: "x mandatory",
        "& > *": {
          scrollSnapAlign: "center",
        },
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      {data.map((item) => (
        <Card
          orientation="horizontal"
          size="sm"
          key={item.title}
          variant="outlined"
          sx={{ display: "block" }}
        >
          <AspectRatio ratio="1" sx={{ minWidth: 250 }}>
            <img
              srcSet={`${item.src}?h=120&fit=crop&auto=format&dpr=2 2x`}
              src={`${item.src}?h=120&fit=crop&auto=format`}
              alt={item.title}
            />
          </AspectRatio>
          <Typography textAlign="center">{item.version}</Typography>
        </Card>
      ))}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import axios from "axios";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DishCard from "./components/DishCard";
import { apiUrl } from "../../constants/api";

export default function DishesMenu({ restaurantId, pack }) {
  const [value, setValue] = React.useState(1);
  const [dishes, setDishes] = useState([]);
  const [categories, setCategories] = useState([]);

  // Fetch dishes data on component mount and update the state with it
  useEffect(() => {
    const fetchDishes = async () => {
      await axios
        .get(`${apiUrl}/menus/${restaurantId}/dishes`)
        .then((res) => {
          setDishes(res.data["dishes"]);
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération des plats :", err);
        });
    };

    fetchDishes();
  }, []);

  // Fetch Categories data on component mount and update the state with it
  useEffect(() => {
    const fetchCategories = async () => {
      await axios
        .get(`${apiUrl}/categories`)
        .then((res) => {
          setCategories(res.data["categories"]);
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération des categories :", err);
        });
    };

    fetchCategories();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getContentByTab = (tabValue) => {
    return dishes
      .filter((dish) => parseInt(dish.category_id) === tabValue)
      .map((dish) => <DishCard key={dish.id} dish={dish} pack={pack} />);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
        centered
      >
        {categories.map((category) => (
          <Tab value={category.id} label={category.name} key={category.id} />
        ))}
      </Tabs>
      {getContentByTab(value)}
    </Box>
  );
}

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  ListItem,
  Rating,
} from "@mui/material";
import DishImagesCarousel from "./DishImagesCarousel";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DishDialog({ dish }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button variant="text" onClick={handleClickOpen} size="small">
        Voir plus
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "black" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {dish.name}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}></Button>
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ backgroundColor: "#ebd4a1" }}
        >
          <Card sx={{ maxWidth: 345, marginTop: "10px" }}>
            <CardActionArea>
              <DishImagesCarousel />
              <CardContent>
                <Box display="flex" justifyContent="space-between">
                  <Typography gutterBottom variant="h5" component="div">
                    {dish.name}
                  </Typography>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    fontWeight="bold"
                    color="red"
                  >
                    {dish.price} DA
                  </Typography>
                </Box>
                <Rating
                  name="read-only"
                  value={dish.rating}
                  readOnly
                  precision={0.1}
                />
                <Typography variant="body2" color="text.secondary">
                  {dish.description}
                </Typography>
                <Typography variant="h5">Ingrédients :</Typography>

                <List aria-labelledby="decorated-list-demo">
                  <ListItem>
                    <Typography variant="body2" textAlign="justify">
                      Laitue romaine🥬 : Une ou deux têtes de laitue romaine
                      lavées et déchiquetées.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2" textAlign="justify">
                      Croûtons 🍞 : Des croûtons de pain grillés ou frits.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2" textAlign="justify">
                      Parmesan 🧀 : Fromage parmesan râpé ou en copeaux.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2" textAlign="justify">
                      Poulet 🍗 : Poitrines de poulet grillées, coupées en
                      morceaux ou en lanières.
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="body2" textAlign="justify">
                      Sauce César 🥣 : Une sauce crémeuse à base d'huile
                      d'olive, d'œufs, de parmesan, de moutarde, d'ail et
                      d'anchois.
                    </Typography>
                  </ListItem>
                </List>
              </CardContent>
            </CardActionArea>
          </Card>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}

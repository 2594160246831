import React from "react";
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const BottomSocialMedia = () => {
  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
      }}
      elevation={3}
    >
      <BottomNavigation showLabels>
        <BottomNavigationAction
          label="Twitter"
          sx={{ color: "#1d9bf0" }}
          icon={<TwitterIcon />}
          href=""
        />
        <BottomNavigationAction
          label="Instagram"
          sx={{ color: "#bd38d0" }}
          icon={<InstagramIcon />}
          href="https://www.instagram.com/latelier_en_ville/?hl=fr"
        />
        <BottomNavigationAction
          label="Facebook"
          sx={{ color: "#106bff" }}
          icon={<FacebookIcon />}
          href="https://www.facebook.com/p/Latelier-en-ville-100075945306960/"
        />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomSocialMedia;

import { useParams } from "react-router-dom";
import "../../App.css";
import MainAppBar from "../../components/MainAppBar";
import DishesMenu from "./DishesMenu";
import BottomSocialMedia from "./components/BottomSocialMedia";
import { useEffect, useState } from "react";
import axios from "axios";
import { apiUrl } from "../../constants/api";
import { Box, CircularProgress } from "@mui/material";

function RestaurantPage() {
  const { restaurantSlug } = useParams();
  const [restaurant, setRestaurant] = useState({});
  const [pack, setPack] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Fetch restaurant info data on component mount and update the state with it
  useEffect(() => {
    const fetchDishes = async () => {
      await axios
        .get(`${apiUrl}/restaurants/search/${restaurantSlug}`)
        .then((res) => {
          setRestaurant(res.data["restaurant"]);
          setPack(res.data["pack"]);
          setIsLoading(false);
        })
        .catch((err) => {
          console.error("Erreur lors de la récupération des plats :", err);
        });
    };

    fetchDishes();
  }, []);

  return (
    <div>
      <MainAppBar restaurantName={restaurant.name} />
      {isLoading ? (
        <div className="flex justify-center py-4">
          <CircularProgress color="inherit" />;
        </div>
      ) : (
        <div>
          <div className="home">
            <div className="card-container">
              <Box sx={{ width: "100%", background: "#ebd4a1" }}>
                <header>
                  <h1
                    className="app-title"
                    style={{ margin: 0, marginTop: "5px" }}
                  >
                    Bienvenue
                  </h1>
                  <h1
                    className="app-title"
                    style={{ fontSize: "22px", margin: 0 }}
                  >
                    à {restaurant.name}
                  </h1>
                </header>
                <DishesMenu restaurantId={restaurant.id} pack={pack} />
              </Box>
            </div>
          </div>
          <BottomSocialMedia />
        </div>
      )}
    </div>
  );
}

export default RestaurantPage;
